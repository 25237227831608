.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page {
  height: 100vh;
  padding: 2% 0% 5% 0%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  transition: transform 1s ease-in-out;
}

.page-enter {
  transform: translate(-100%, 0);
}

.page-enter-active {
  transform: translate(0, 0);
}

.page-exit {
  transform: translate(-100%, 0);
}

.page--prev.page-enter {
  transform: translate(100%, 0);
}

.page--prev.page-enter-active {
  transform: translate(0, 0);
}

.page--prev.page-exit {
  transform: translate(100%, 0);
}


.card {
  background-color: #3d445f;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.header {
  width: 100vw;
  padding: 0px 0px 20px 0px;
  text-align: center;
}

.btn-customPrimary {
  background-color: #477589;
  color: white
}